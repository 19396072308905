import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Authentication from './pages/Authentication';
import DashboardLayout from './layouts/dashboardLayout';
import CookHome from './pages/CookHome';
import DeliveredOrders from './pages/DeliveredOrders';
import ProtectedRoute from './ProtectedRoute';
import { useEffect, useState } from 'react';
import AdminHome from './pages/AdminHome';
import PickOrders from './pages/PickOrders';
import FoodOrderReceipt from './pages/FoodOrderReceipt';

function App () {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const authUser = () => {
    const isValidUser = Number(localStorage.getItem('AccessLevel'));

    if (isValidUser === 1 || isValidUser === 2) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }

  useEffect(() => {
    authUser();
  }, [isAuthenticated]);

  useEffect(() => {
    authUser();
  }, [])

  return (
    <Router>
      <Routes>
        <Route
          path='/'
          element={
            <Authentication setIsAuthenticated={setIsAuthenticated} />
          }
        />
        <Route element={<DashboardLayout />}>
          <Route
            path="/adminhome/all"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <AdminHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/adminhome/:catId"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <AdminHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cookhome"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <CookHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/deliveredOrders"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <DeliveredOrders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pickorders"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <PickOrders />
              </ProtectedRoute>
            }
          />
           
        </Route>
      </Routes>
    </Router>
  )
}

export default App
