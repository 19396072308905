import React, { createContext, useState, useContext } from "react";

//Creating a context
export const CategoryContext = createContext();

const CategoryContextProvider = ({ children }) => {
  //State to hold user data
  const [categoriesSidebar, setCategoriesSidebar] = useState([]);

  return (
    //Providing the state and setter function through context
    <CategoryContext.Provider value={{ categoriesSidebar, setCategoriesSidebar }}>
      {children}
    </CategoryContext.Provider>
  );
};

//Custom hook to consume the context
const useCategoryContext = () => {
  return useContext(CategoryContext);
};

export { CategoryContextProvider, useCategoryContext };
