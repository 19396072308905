import React, { useEffect, useState, useRef } from "react";
import { mainAxios } from "../middleware/Interceptor";
import OrderBox from "../components/OrderBox";
import Lottie from "react-lottie";
import * as animationData from "../assets/loader.json";
import { EventHubConsumerClient } from "@azure/event-hubs";
import FoodOrderReceipt from "./FoodOrderReceipt";
import NotificationSound from "../notification-sound.mp3";




const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function CookHome() {
  const [orders, setOrders] = useState({});
  const [cartDetails, setCartDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [actionLoader, setActionLoader] = useState(null);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [showReceipt, setShowReceipt] = useState(false);

  const audioPlayer = useRef(null);

  const [printData, setPrintData] = useState(null);

  const EventHubCreds = {
    EventHubConnectionString: process.env.REACT_APP_EVENTHUBCONNECTIONSTRING,
    EventHubName: process.env.REACT_APP_EVENTHUBNAME,
    EventHubConsumerGroup: "$Default",
  };

  const { EventHubConnectionString, EventHubName, EventHubConsumerGroup } =
    EventHubCreds;

  const getOrders = async () => {
    try {
      isInitialRender && setIsLoading(true);
      const getOrders = await mainAxios.get("/GetActiveOrders");

      const groupByCartId =
        !!getOrders?.data?.orderDetails &&
        Object.groupBy(getOrders?.data?.orderDetails, ({ CartId }) => CartId);
      setOrders(groupByCartId);
      setCartDetails(getOrders?.data?.cartDetails);
      setIsLoading(false);
      setActionLoader(null);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleDeliver = async (cartId, orderSts) => {
    try {
      if (orderSts === 1) {
        const orderData = {
          orderDate: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,

          items: cartId in orders && orders[cartId],
          totalAmount: cartDetails.filter((item) => item.CartId === cartId)[0]
            .TotalAmount,
        };
       
        setPrintData(orderData);
       
        setShowReceipt(true);
      }

      const payload = { cartId, status: orderSts + 1 };
      setActionLoader(cartId);
      const deliverOrder = await mainAxios.post("/DeliverOrder", payload);
      

      if (deliverOrder?.status === 201) {
        getOrders();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const playNotificationSound = () => {
    audioPlayer.current.play();    
  };

  const ProcessEventHubsEvents = (datafromhub) => {
    if (
      datafromhub &&
      datafromhub.length > 0 &&
      datafromhub[0].body.status === 0
    ) {
      console.log(datafromhub[0].body, 'EVENT_HUB');
      const currentCart = datafromhub[0].body.cartDetails[0];
      setCartDetails((prevState) => [...prevState, currentCart]);

      const orderDetailsFromEvent = datafromhub[0].body.orderDetails;
      const groupByCartId = Object.groupBy(
        orderDetailsFromEvent,
        ({ CartId }) => CartId
      );
      setOrders((prevState) => ({ ...prevState, ...groupByCartId }));

      playNotificationSound();
     
    }
  };

  useEffect(() => {
    const EventHubclient = new EventHubConsumerClient(
      EventHubConsumerGroup,
      EventHubConnectionString,
      EventHubName
    );

    const subscribe = EventHubclient.subscribe({
      processEvents: ProcessEventHubsEvents,
      processError: (err) => {
        console.log("ErrorfromEventhub", err);
        if (
          err
            .toString()
            .includes("Exceeded the maximum number of allowed receivers")
        ) {
          console.log(
            "Disabled Realtime Updates(Exceeded the maximum connection)"
          );
          subscribe.close();
          EventHubclient.close();
        }
      },
    });

    return () => {
      subscribe.close();
      EventHubclient.close();
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getOrders();
    setIsInitialRender(false);
  }, []);

  return (
    <div className="flex flex-wrap gap-5 border radius sm p-5 px-[37px] w-full h-full">
      {isLoading && (
        <div className="w-[100%] min-h-180 flex items-center justify-center">
          <Lottie options={defaultOptions} height={100} width={100} />
        </div>
      )}
      {!isLoading &&
        Object.keys(orders).length > 0 &&
        Object.entries(orders).map((obj, i) => {
          const orderStatus = cartDetails?.filter(
            (item) => item?.CartId === Number(obj[0])
          )[0]?.Cook_Status;
          return (
            <OrderBox
              orderStatus={orderStatus}
              key={i}
              orderId={obj[0]}
              orders={obj[1]}
              cartDetails={cartDetails}
              handleDeliver={handleDeliver}
              index={i}
              actionLoader={actionLoader}
            />
          );
        })}
      {!isLoading && Object.keys(orders).length === 0 && (
        <div className="flex justify-center items-center w-full h-180">
          <div className="w-96 h-96">
            <img
              src={require("../assets/empty_cart.jpg")}
              alt="Empty Orders"
              className="w-full h-full"
            />
          </div>
        </div>
      )}
      <div style={{ display: "none" }}>
      <audio ref={audioPlayer} src={NotificationSound} />
      </div>
      <div style={{ display: "none" }}>
        {showReceipt && (
          <FoodOrderReceipt
            data={printData}
            onPrintComplete={() => setShowReceipt(false)} // Callback function to hide the receipt after printing
          />
        )}
      </div>
    </div>
  );
}

export default CookHome;
