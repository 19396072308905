import React, { useEffect, useState } from "react";
import "../styles/addFoodItems.css";
import { mainAxios } from "../middleware/Interceptor";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useForm, Controller } from "react-hook-form";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useParams, useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import * as animationData from '../assets/loader.json'
import EditIcon from '@mui/icons-material/Edit';
import { useCategoryContext } from "../context/CategoryProvider";

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function AdminHome() {
  const [backupData, setBackupData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [foodItems, setFoodItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [formType, setFormType] = useState("");
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [previewImg, setPreviewImg] = useState('');
  const [customErrorMsg, setCustomErrorMsg] = useState('');
  const [catModal, setCatModal] = useState(false);
  const [manageType, setManageType] = useState(1);
  const [defaultCatName, setDefaultName] = useState(1)

  const { catId } = useParams();
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');
  const { setCategoriesSidebar } = useCategoryContext();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    clearErrors,
    reset
  } = useForm();

  const getAllDetails = async () => {
    try {
      const userID = { userId };
      setIsLoading(true);
      const data = await mainAxios.post("/GetFoodItems", userID);
      setBackupData(data?.data);

      const obj = {};
      const foodDetails = data?.data?.allFoodDetails;
      foodDetails?.length > 0 &&
        foodDetails.forEach((element) => {
          if (element.CategoryId in obj) {
            obj[element.CategoryId] += 1;
          } else {
            obj[element.CategoryId] = 1;
          }
        });

      const catDetails = data?.data?.catDetails;
      catDetails?.length > 0 &&
        catDetails.forEach((category) => {
          if (category.CategoryId in obj) {
            category.itemCount = obj[category.CategoryId];
          }
        });

      setCategories(catDetails);
      setAllCategories(data?.data?.allCatDetails);
      setCategoriesSidebar(catDetails);
      setFoodItems(data?.data?.allFoodDetails);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error, "failed");
    }
  };

  const handleCategoryById = (catid) => {
    const id = Number(catid);

    if (id !== 0) {
      const filteredCategories =
        !!backupData &&
        backupData.allFoodDetails?.filter(
          (category) => category.CategoryId === id
        );
      setFoodItems(filteredCategories);
    } else {
      setFoodItems(backupData?.allFoodDetails);
    }
  };

  const handleItem = (type, item) => {
    setFormType(type);
    setOpen(true);
    if (type === "edit") {
      setCurrentItem(item);
    }
  };

  const handleNewCategory = () => {
    setCatModal(true);
  }

  const handleClose = () => {
    setOpen(false);
    setCurrentItem({});
    setPreviewImg('');
    setCustomErrorMsg('');
    clearErrors();
    reset();
    setIsImageChanged(false);
    setCatModal(false);
    setManageType(1);
    setDefaultName(1);
    const getNameById = allCategories?.filter(item => item.CategoryId === defaultCatName)[0];
    setValue('editCategoryName', getNameById?.CategoryName);
    setValue('categoryStatus', getNameById?.Status);
    if (formType === 'create' || catId === 'newCategory') {
      navigate(`/adminhome/all`);
    }
  };

  const onEditSubmit = async (data) => {
    try {
      if (Number(data.itemQuantity) === 0 && Number(data.Status) === 1) {
        setCustomErrorMsg('To enable this item, the item quantity must be greater than 0');
        return false;
      }
      const formData = new FormData();
      formData.append("categoryId", Number(data.Category));
      formData.append("itemName", data.ItemName.trim());
      formData.append("itemAmount", Number(data.ItemAmount).toFixed(2).trim());
      formData.append("itemQuantity", Number(data.itemQuantity));
      formData.append("status", Number(data.Status));
      formData.append("productId", Number(currentItem.ProductId));
      formData.append("itemImage", !isImageChanged ? '' : data.EditItemImage);
      formData.append("updateImage", isImageChanged ? '' : data.EditItemImage);

      const updateFood = await mainAxios.post("/UpdateFoodItem", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (updateFood.status === 201) {
        setIsImageChanged(false);
        getAllDetails();
        handleClose();
        setCurrentItem({});
      }
    } catch (error) {
      if (error.response.status === 409) {
        setCustomErrorMsg('Item Already Exists');
        setIsImageChanged(false);
      }
    }
  };

  const onCreateSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("categoryId", Number(data.newItemCategory));
      formData.append("itemName", data.newItemName.trim());
      formData.append(
        "itemAmount",
        Number(data.newItemAmount).toFixed(2).trim()
      );
      formData.append("itemQuantity", Number(data.newItemQuantity) || 0);
      formData.append("itemImage", data.EditItemImage);

      const createFood = await mainAxios.post("/CreateFoodItem", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (createFood.status === 201) {
        getAllDetails();
        handleClose();
      }
    } catch (error) {
      if (error.response.status === 409) {
        setCustomErrorMsg('Item name already exists');
      }
    }
  };

  const handleCategory = async (data) => {
    try {
      let payload = {};
      if (manageType === 1) {
        payload = {
          categoryName: data.newCategory
        }
        const createCategory = await mainAxios.post("/addfoodcategory", payload);
        if (createCategory.status === 201) {
          getAllDetails();
          handleClose();
        }
      } else {
        payload = {
          categoryId: Number(data.categoryType),
          categoryName: data.editCategoryName,
          status: Number(data.categoryStatus)
        }
        const updateCategory = await mainAxios.post("/updatefoodcategory", payload);
        if (updateCategory.status === 201 || updateCategory.status === 204) {
          getAllDetails();
          handleClose();
        }
      }
    } catch (error) {
      setCustomErrorMsg(error.response.data.message);
    }
  }

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      !isImageChanged && setPreviewImg('');
      return false;
    }

    const file = e.target.files[0];
    setIsImageChanged(true);
    setPreviewImg(URL.createObjectURL(file));
  };

  useEffect(() => {
    const getNameById = allCategories?.filter(item => item.CategoryId === defaultCatName)[0];
    setValue('editCategoryName', getNameById?.CategoryName);
    setValue('categoryStatus', getNameById?.Status);
  }, [defaultCatName, allCategories]);

  useEffect(() => {
    if (catId === "create") {
      handleItem("create");
    } else if (catId === "newCategory") {
      handleNewCategory();
    } else if (catId === undefined) {
      handleCategoryById(0);
    } else {
      handleCategoryById(catId)
    }

    return () => { }
  }, [catId, backupData]);

  useEffect(() => {
    if (open && formType === 'edit') {
      setValue("ItemName", currentItem.ItemName || "");
      setValue("ItemAmount", parseFloat(currentItem.ItemAmount).toFixed(2) || "");
      setValue("Category", currentItem.CategoryId);
      setValue("itemQuantity", currentItem.ItemQuantity);
      setValue("Status", currentItem.Status);
      setValue("EditItemImage", currentItem.ItemImage);
      setPreviewImg(currentItem.ItemImage);
    } else {
      setValue("newItemAmount", Number(0).toFixed(2));
    }
  }, [open, currentItem, setValue, formType]);

  useEffect(() => {
    getAllDetails();

    return () => { }
  }, []);

  return (
    <div className="addFoodItemsContainer border rounded-sm h-full min-h-180 relative">
      {isLoading && (
        <div className="w-[100%] min-h-150 flex items-center justify-center">
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
          />
        </div>
      )}
      {!isLoading && (
        <div className="foodContainer">
          {!!foodItems
            && foodItems?.length > 0
            && foodItems.map((food) => food.Status === 1 && (
              <div className="itemBox" key={food.ProductId}>
                <div className="foodImgOuter">
                  <img
                    className="foodImg"
                    src={food.ItemImage || require("../assets/food.jpg")}
                    alt={food.ItemName}
                  />

                </div>
                <div className="textWrapper">
                  <p className="textCatName">{food.ItemName}</p>
                  <p className="textItemPrice">₹{food.ItemAmount}</p>
                  <button
                    className="primaryBtn flex items-center"
                    onClick={() => handleItem("edit", food)}
                  >
                    <EditIcon fontSize="small" className="mr-2" />
                    Edit
                  </button>
                </div>
              </div>
          ))}
          {foodItems.length > 0 && foodItems.map((food) => food.Status === 0 && (
            <div className="itemBox" key={food.ProductId}>
              <div className="foodImgOuter">
                <img
                  className="foodImg disabled"
                  src={food.ItemImage || require("../assets/food.jpg")}
                  alt={food.ItemName}
                />
              </div>
              <div className="textWrapper">
                <p className="textCatName">{food.ItemName}</p>
                <p className="textItemPrice">₹{food.ItemAmount}</p>
                <button
                  className="primaryBtn flex items-center"
                  onClick={() => handleItem("edit", food)}
                >
                  <EditIcon fontSize="small" className="mr-2" />
                  Edit
                </button>
              </div>
            </div>
          ))}
          {foodItems.length === 0 && (
            <div className='flex flex-col justify-center items-center w-full h-180'>
              <div className='w-96 h-96 emptyBox'></div>
              <h2 className="text-center font-semibold mx-auto text-xl text-red-600 uppercase">
                No food items are available
              </h2>
            </div>
          )}
        </div>
      )}
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={boxStyle}>
            <div className="flex justify-end relative">
              <CloseRoundedIcon
                className="absolute -top-6 -right-6 cursor-pointer"
                onClick={handleClose}
              />
            </div>
            {formType === 'edit' && (
              <form onSubmit={handleSubmit(onEditSubmit)} className='mx-auto'>
                <h5 className='text-sm font-semibold'>Edit Item</h5>
                <div className="flex justify-between">
                  <div className="w-[48%]">
                    <div className="inputField">
                      <label htmlFor="ItemName">Item Name</label>
                      <Controller
                        name="ItemName"
                        control={control}
                        rules={{
                          required: 'Item Name is required',
                          minLength: {
                            value: 3,
                            message: 'Item Name must be greater than 2 alphabetic characters'
                          },
                          pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message: 'Item Name must contain only alphabetic characters'
                          }
                        }}
                        render={({ field }) =>
                          <input
                            {...field}
                            onInput={() => setCustomErrorMsg('')}
                          />
                        }
                      />
                      <p className={`errorField m-0 ${errors?.ItemName?.message ? 'visible' : 'invisible'}`}>
                        {errors?.ItemName?.message
                          ? errors?.ItemName?.message
                          : 'Error'
                        }
                      </p>
                    </div>
                    <div className="inputField">
                      <label htmlFor="itemQuantity">Item Quantity</label>
                      <Controller
                        name="itemQuantity"
                        control={control}
                        rules={{
                          required: 'Item Quantity is required',
                          min: {
                            value: 0,
                            message: 'Item Quantity must be positive'
                          },
                          pattern: {
                            value: /^\d+$/,
                            message: 'Please enter a valid number',
                          },
                        }}
                        render={({ field }) => <input {...field} />}
                      />
                      <p className={`errorField m-0 ${errors?.itemQuantity?.message ? 'visible' : 'invisible'}`}>
                        {errors?.itemQuantity?.message
                          ? errors?.itemQuantity?.message
                          : 'Error'
                        }
                      </p>
                    </div>
                    <div className="inputField">
                      <label htmlFor="ItemAmount">Item Amount</label>
                      <Controller
                        name="ItemAmount"
                        control={control}
                        rules={{
                          required: 'Item Amount is required',
                          pattern: {
                            value: /^(?=.*\d)\d*(?:\.\d*)?$/,
                            message: 'Item Amount must be a number',
                          },
                          validate: (value) => {
                            return parseFloat(value) >= 1 || 'Item amount must be greater than or equal to 1';
                          }
                        }}
                        render={({ field }) => <input {...field} />}
                      />
                      <p className={`errorField m-0 ${errors?.ItemAmount?.message ? 'visible' : 'invisible'}`}>
                        {errors?.ItemAmount?.message
                          ? errors?.ItemAmount?.message
                          : 'Error'
                        }
                      </p>
                    </div>
                    <div className="inputField">
                      <label htmlFor="Category">Category</label>
                      <Controller
                        name="Category"
                        control={control}
                        rules={{ required: 'Category is required' }}
                        render={({ field }) => (
                          <select {...field}>
                            {categories.length > 0 && categories.map(item => (
                              <option value={item.CategoryId} key={item.CategoryId}>
                                {item.CategoryName}
                              </option>
                            ))}
                          </select>
                        )}
                      />
                      <p className={`errorField m-0 ${errors?.Category?.message ? 'visible' : 'invisible'}`}>
                        {errors?.Category?.message
                          ? errors?.Category?.message
                          : 'Error'
                        }
                      </p>
                    </div>
                  </div>
                  <div className="w-[48%]">
                    <div className="inputField">
                      <label htmlFor="Status">Status</label>
                      <Controller
                        name="Status"
                        control={control}
                        rules={{ required: 'Status is required' }}
                        render={({ field }) => (
                          <select {...field}>
                            <option value={0}>Disable</option>
                            <option value={1}>Enable</option>
                          </select>
                        )}
                      />
                      <p className={`errorField m-0 ${errors?.Status?.message ? 'visible' : 'invisible'}`}>
                        {errors?.Status?.message
                          ? errors?.Status?.message
                          : 'Error'
                        }
                      </p>
                    </div>
                    <div className="inputField">
                      <label htmlFor="EditItemImage">Choose Image</label>
                      <Controller
                        name="EditItemImage"
                        control={control}
                        rules={{
                          required: 'Item image is required',
                        }}
                        render={({ field }) => (
                          <input
                            type="file"
                            id="EditItemImage"
                            accept="image/*"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              file !== undefined && field.onChange(file);
                              file !== undefined && onSelectFile(e);
                            }}
                          />
                        )}
                      />
                      <p className={`errorField m-0 ${errors?.EditItemImage?.message ? 'visible' : 'invisible'}`}>
                        {errors?.EditItemImage?.message
                          ? errors?.EditItemImage?.message
                          : 'Error'
                        }
                      </p>
                    </div>
                    {previewImg && (
                      <div className="imagePreview mt-6 rounded-xl overflow-hidden w-[215px] h-[125px] mx-auto">
                        <img
                          className="object-cover w-full h-full"
                          src={previewImg}
                          alt="Preview"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="submitField">
                  <button className="px-5 py-1 primaryBtn rounded" type="submit">Submit</button>
                </div>
                <p
                  className={`errorField text-center pt-2 ${customErrorMsg.length > 0 ? 'visible' : 'invisible'}`}
                >
                  {customErrorMsg.length > 0
                    ? customErrorMsg
                    : ''
                  }
                </p>
              </form>
            )}
            {formType === 'create' && (
              <form onSubmit={handleSubmit(onCreateSubmit)} className='mx-auto'>
                <h5 className='text-sm font-semibold'>Add Item</h5>
                <div className="flex justify-between">
                  <div className="w-[48%]">
                    <div className="inputField">
                      <label htmlFor="newItemName">Item Name</label>
                      <Controller
                        name="newItemName"
                        control={control}
                        rules={{
                          required: 'Item Name is required',
                          minLength: {
                            value: 3,
                            message: 'Item Name must be greater than 2 alphabetic characters'
                          },
                          pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message: 'Item Name must contain only alphabetic characters'
                          }
                        }}
                        render={({ field }) =>
                          <input
                            {...field}
                            onInput={() => setCustomErrorMsg('')}
                          />
                        }
                      />
                      <p className={`errorField m-0 ${errors?.newItemName?.message ? 'visible' : 'invisible'}`}>
                        {errors?.newItemName?.message
                          ? errors?.newItemName?.message
                          : 'Error'
                        }
                      </p>
                    </div>
                    <div className="inputField">
                      <label htmlFor="newItemQuantity">Item Quantity</label>
                      <Controller
                        name="newItemQuantity"
                        control={control}
                        rules={{
                          required: 'Item Quantity is required',
                          min: {
                            value: 0,
                            message: 'Item Quantity must be positive'
                          },
                          pattern: {
                            value: /^\d+$/,
                            message: 'Please enter a valid number',
                          },
                        }}
                        render={({ field }) => <input {...field} />}
                      />
                      <p className={`errorField m-0 ${errors?.newItemQuantity?.message ? 'visible' : 'invisible'}`}>
                        {errors?.newItemQuantity?.message
                          ? errors?.newItemQuantity?.message
                          : 'Error'
                        }
                      </p>
                    </div>
                    <div className="inputField">
                      <label htmlFor="newItemAmount">Item Amount</label>
                      <Controller
                        name="newItemAmount"
                        control={control}
                        rules={{
                          required: 'Item Amount is required',
                          pattern: {
                            value: /^(?=.*\d)\d*(?:\.\d*)?$/,
                            message: 'Item Amount must be a number',
                          },
                          validate: (value) => {
                            return parseFloat(value) >= 1 || 'Item amount must be greater than or equal to 1';
                          }
                        }}
                        render={({ field }) => <input {...field} />}
                      />
                      <p className={`errorField m-0 ${errors?.newItemAmount?.message ? 'visible' : 'invisible'}`}>
                        {errors?.newItemAmount?.message
                          ? errors?.newItemAmount?.message
                          : 'Error'
                        }
                      </p>
                    </div>
                    <div className="inputField">
                      <label htmlFor="newItemCategory">Category</label>
                      <Controller
                        name="newItemCategory"
                        control={control}
                        rules={{ required: 'Category is required' }}
                        render={({ field }) => (
                          <select {...field} defaultValue={''}>
                            <option value={''} disabled>Category</option>
                            {categories.length > 0 && categories.map(item => (
                              <option value={item.CategoryId} key={item.CategoryId}>
                                {item.CategoryName}
                              </option>
                            ))}
                          </select>
                        )}
                      />
                      <p className={`errorField m-0 ${errors?.newItemCategory?.message ? 'visible' : 'invisible'}`}>
                        {errors?.newItemCategory?.message
                          ? errors?.newItemCategory?.message
                          : 'Error'
                        }
                      </p>
                    </div>
                  </div>
                  <div className="w-[48%]">
                    <div className="inputField">
                      <label htmlFor="EditItemImage">Choose Image</label>
                      <Controller
                        name="EditItemImage"
                        control={control}
                        rules={{
                          required: 'Item image is required',
                        }}
                        render={({ field }) => (
                          <input
                            type="file"
                            id="EditItemImage"
                            accept="image/*"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              file !== undefined && field.onChange(file);
                              file !== undefined && onSelectFile(e);
                            }}
                          />
                        )}
                      />
                      <p className={`errorField m-0 ${errors?.EditItemImage?.message ? 'visible' : 'invisible'}`}>
                        {errors?.EditItemImage?.message
                          ? errors?.EditItemImage?.message
                          : 'Error'
                        }
                      </p>
                    </div>
                    {previewImg && (
                      <div className="imagePreview mt-6 rounded-xl overflow-hidden w-[215px] h-[125px] mx-auto">
                        <img
                          className="object-cover w-full h-full"
                          src={previewImg}
                          alt="Preview"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="submitField">
                  <button className="px-5 py-1 primaryBtn rounded" type="submit">Submit</button>
                </div>
                <p
                  className={`errorField text-center pt-2 ${customErrorMsg.length > 0 ? 'visible' : 'invisible'}`}
                >
                  {customErrorMsg.length > 0
                    ? customErrorMsg
                    : ''
                  }
                </p>
              </form>
            )}
          </Box>
        </Modal>
      )}
      {catModal && (
        <Modal
          open={catModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={boxStyle}>
            <div className="flex justify-end relative">
              <CloseRoundedIcon
                className="absolute -top-6 -right-6 cursor-pointer"
                onClick={handleClose}
              />
            </div>
            <form onSubmit={handleSubmit(handleCategory)} className='mx-auto'>
              <h5 className='text-sm font-semibold'>Manage Categories</h5>
              <div className="flex justify-between">
                <div className="w-[100%]">
                  <div className="inputField flex gap-1">
                    <label htmlFor="manageCategory">Manage Category</label>
                    <Controller
                      name="manageCategory"
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          onChange={(e) => {
                            setManageType(Number(e.target.value));
                            setCustomErrorMsg('');
                          }}
                        >
                          <option value={1}>
                            Add new category
                          </option>
                          <option value={2}>
                            Edit category
                          </option>
                        </select>
                      )}
                    />
                    <p className={`errorField m-0 ${errors?.manageCategory?.message ? 'visible' : 'invisible'}`}>
                      {errors?.manageCategory?.message
                        ? errors?.manageCategory?.message
                        : 'Error'
                      }
                    </p>
                  </div>
                  {manageType === 1 && (
                    <div className="inputField flex gap-1">
                      <label htmlFor="newCategory">New Category</label>
                      <Controller
                        name="newCategory"
                        control={control}
                        rules={{
                          required: 'Category Name is required',
                          minLength: {
                            value: 3,
                            message: 'Category Name must be greater than 2 alphabetic characters'
                          },
                          pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message: 'Category Name must contain only alphabetic characters'
                          }
                        }}
                        render={({ field }) =>
                          <input
                            {...field}
                            onInput={() => setCustomErrorMsg('')}
                          />
                        }
                      />
                      <p className={`errorField m-0 ${errors?.newCategory?.message ? 'visible' : 'invisible'}`}>
                        {errors?.newCategory?.message
                          ? errors?.newCategory?.message
                          : 'Error'
                        }
                      </p>
                    </div>
                  )}
                  {manageType === 2 && (
                    <>
                      <div className="inputField flex gap-1">
                        <label htmlFor="categoryType">Category</label>
                        <Controller
                          name="categoryType"
                          control={control}
                          defaultValue={allCategories[0]?.CategoryId}
                          rules={{ required: 'Category is required' }}
                          render={({ field }) => (
                            <select
                              {...field}
                              className="capitalize"
                              onChange={(e) => {
                                setDefaultName(Number(e.target.value));
                                setValue('categoryType', Number(e.target.value))
                                const status = allCategories.filter(item => item.CategoryId === Number(e.target.value))[0].Status;
                                setValue('categoryStatus', status);
                              }}
                            >
                              {allCategories?.length > 0 && allCategories.map(item => (
                                <option value={item.CategoryId} key={item.CategoryId}>
                                  {item.CategoryName}
                                </option>
                              ))}
                            </select>
                          )}
                        />
                        <p className={`errorField m-0 ${errors?.categoryType?.message ? 'visible' : 'invisible'}`}>
                          {errors?.categoryType?.message
                            ? errors?.categoryType?.message
                            : 'Error'
                          }
                        </p>
                      </div>
                      <div className="inputField flex gap-1">
                        <label htmlFor="editCategoryName">Edit Category Name</label>
                        <Controller
                          name="editCategoryName"
                          control={control}
                          rules={{
                            required: 'Category Name is required',
                            minLength: {
                              value: 3,
                              message: 'Category Name must be greater than 2 alphabetic characters'
                            },
                            pattern: {
                              value: /^[A-Za-z\s]+$/,
                              message: 'Category Name must contain only alphabetic characters'
                            }
                          }}
                          render={({ field }) => (
                            <input {...field} />
                          )}
                        />
                        <p className={`errorField m-0 ${errors?.editCategoryName?.message ? 'visible' : 'invisible'}`}>
                          {errors?.editCategoryName?.message
                            ? errors?.editCategoryName?.message
                            : 'Error'
                          }
                        </p>
                      </div>
                      <div className="inputField flex gap-1">
                        <label htmlFor="categoryStatus">Category Status</label>
                        <Controller
                          name="categoryStatus"
                          control={control}
                          rules={{ required: 'Category Status is required' }}
                          render={({ field }) => (
                            <select
                              {...field}
                              onChange={(e) => {
                                setValue('categoryStatus', Number(e.target.value))
                              }}
                            >
                              <option value={1} >Enable</option>
                              <option value={0} >Disable</option>
                            </select>
                          )}
                        />
                        <p className={`errorField m-0 ${errors?.categoryStatus?.message ? 'visible' : 'invisible'}`}>
                          {errors?.categoryStatus?.message
                            ? errors?.categoryStatus?.message
                            : 'Error'
                          }
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="submitField">
                <button className="px-5 py-1 primaryBtn rounded" type="submit">Submit</button>
              </div>
              <p
                className={`errorField text-center pt-2 ${customErrorMsg.length > 0 ? 'visible' : 'invisible'}`}
              >
                {customErrorMsg.length > 0
                  ? customErrorMsg
                  : ''
                }
              </p>
            </form>
          </Box>
        </Modal>
      )}
    </div>
  );
}
export default AdminHome;
