import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import VideoSrc from "../assets/FoodAd.mp4";
import PageGradient from "../components/PageGradient";
import PageIllustration from "../components/PageIllustration";
import { mainAxios } from "../middleware/Interceptor";
import { useForm, Controller } from "react-hook-form";

export default function SignIn({setIsAuthenticated}) {
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const [responseError, setResponseError] = useState('');

  const {
    handleSubmit,
    formState: {errors},
    control,
  } = useForm();

  const onSubmit = async (data) => {
    const { username, password } = data;

    try {
      const payload = {username, password}
      const authenticate = await mainAxios.post(`/AdminLogin`, payload);
      if (authenticate?.data?.data[0].AccessLevel === 1) {
        localStorage.setItem('AccessLevel', authenticate?.data?.data[0].AccessLevel);
        localStorage.setItem('userId', authenticate?.data?.data[0].UserId);
        localStorage.setItem('userName', authenticate?.data?.data[0].UserName);
        navigate('/adminhome/all');
        setIsAuthenticated(true);
      } else if (authenticate?.data?.data[0].AccessLevel === 2) {
        navigate('/cookhome');
        localStorage.setItem('AccessLevel', authenticate?.data?.data[0].AccessLevel);
        localStorage.setItem('userId', authenticate?.data?.data[0].UserId);
        localStorage.setItem('userName', authenticate?.data?.data[0].UserName);
        setIsAuthenticated(true);
      }
    } catch (error) {
      setResponseError(error.message);
    }
  };

  return (
    <div className="relative flex flex-col items-stretch h-screen overflow-y-auto rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark text-gray-700 dark:text-gray-300 overflow-x-hidden">
      <PageIllustration />
      <div className="flex flex-wrap h-full">
        <div className="hidden w-full lg:flex lg:flex-col lg:justify-center lg:w-2/4">
          <video
            ref={videoRef}
            className="w-full h-full object-cover"
            autoPlay
            loop
            muted
          >
            <source src={VideoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="relative flex flex-col justify-center w-full border-stroke dark:border-strokedark lg:w-2/4 lg:border-l-2">
          <PageGradient />
          <div className="relative w-full max-w-2xl mx-auto p-4 sm:p-12.5 xl:p-17.5 flex flex-col items-center text-center sm:text-left sm:block">
            <h1 className="mb-1.5 text-2xl font-bold text-black dark:text-gray-300 sm:text-title-xl2">
              WELCOME JETZFOOD!
            </h1>
            <span className="mb-9 block font-medium">
              Eating is a necessity, but cooking is an art.
            </span>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="username"
                control={control}
                rules={{
                  required: 'Username is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                }}
                render={({ field }) => (
                  <input
                    {...field}
                    type="text"
                    className={`block py-2 ps-2 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    placeholder="Mail Id"
                  />
                )}
              />
              <p className={`${errors?.username?.message ? 'visible' : 'invisible'} text-danger text-sm`}>
                {errors?.username?.message
                  ? errors?.username?.message
                  : 'Error'
                }
              </p>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: 'Password is required',
                  minLength: {
                    value: 5,
                    message: 'Password must be at least 5 characters long'
                  }
                }}
                render={({ field }) => (
                  <input
                    {...field}
                    type="password"
                    className="block py-2 ps-2 mt-5 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Password"
                  />
                )}
              />
              <p className={`${errors.password?.message ? 'visible' : 'invisible'} text-danger text-sm`}>
                {errors.password?.message ? errors.password?.message : 'Error'}
              </p>
              <button
                className="btn--primary mt-8 w-80 flex gap-3"
                aria-controls="login"
                type="submit"
              >
                Login
              </button>
              <p className={`${responseError?.length > 0 ? 'visible' : 'invisible'} text-danger text-sm mt-1`}>
                {responseError?.length > 0 ? responseError : 'Error'}
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
