import React, { useState, useEffect } from 'react';
import UpdateIcon from '@mui/icons-material/Update';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ClockLoader from "react-spinners/ClockLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function OrderBox({
  orderStatus,
  index,
  orderId,
  orders,
  cartDetails,
  handleDeliver,
  actionLoader
}) {
  const [remainingTime, setRemainingTime] = useState(null);
  const [delayed, setDelayed] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const order = cartDetails.find(item => item.CartId === Number(orderId));
    if (order && order.Cook_Status === 2) {
      const updatedTime = new Date(order.Preparing_Date);
      const delayTime = updatedTime.getTime() + 15 * 60 * 1000;
      const interval = setInterval(() => {
        const currentTime = Date.UTC(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date().getHours(),
          new Date().getMinutes(),
          new Date().getSeconds(),
          new Date().getMilliseconds()
        );
        const timeDifference = Math.floor((delayTime - currentTime) / 1000);
        if (timeDifference <= 0) {
          setDelayed(true);
          clearInterval(interval);
        } else {
          setRemainingTime(timeDifference);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [orderId, cartDetails]);

  useEffect(() => {
    if (actionLoader) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [actionLoader])

  return (
    <>
      {orderStatus !== 4 && (
        <div>
          <div
            className='shadow p-[13px] border w-[330px] rounded-[12px] bg-white'
            style={{
              border: '1px solid #BBB',
              boxShadow: '0px 12px 60px 0px rgba(24, 24, 24, 0.12)'
            }}
          >
            <div className='cardHeader text-left mb-2.5 flex justify-between'>
              <p className='text-slate-800 font-medium text-lg'>
                Token : {`#${orderId}`}
              </p>
              {orderStatus === 1 && (
                <div
                  className='rounded-[16px] flex justify-center items-center'
                  style={{ background: '#E2EEFF' }}
                >
                  <p className='font-medium text-base flex items-center py-[5px] px-2.5' style={{ color: "#003F9D" }}>
                    <AccessTimeIcon className="mr-2" />
                    Waiting
                  </p>
                </div>
              )}
              {orderStatus === 2 && (delayed ? (
                <div
                  className='rounded-[16px] flex justify-center items-center'
                  style={{ background: '#FCD7D8' }}
                >
                  <p
                    className='font-medium text-base text-red-600 flex items-center py-[5px] px-2.5'
                    style={{ color: '#FC1914' }}
                  >
                    <UpdateIcon className="mr-2" /> Delay
                  </p>
                </div>
              ) : (
                <>
                  {remainingTime !== null ? (
                    <div
                      className='rounded-[16px] flex justify-center items-center'
                      style={{ background: '#FFF4DD' }}
                    >
                      <p className='font-medium text-base flex items-center py-[5px] px-2.5' style={{ color: "#F8A503" }}>
                        <AccessTimeIcon className="mr-2" />
                        {formatTime(remainingTime)}
                      </p>
                    </div>
                  ) : (
                    <div
                      className='rounded-[16px] flex justify-center items-center'
                      style={{ background: '#E2EEFF' }}
                    >
                      <p className='font-medium text-base flex items-center py-[5px] px-2.5' style={{ color: "#003F9D" }}>
                        <AccessTimeIcon className="mr-2" />
                        {'Waiting'}
                      </p>
                    </div>
                  )}
                </>
              ))}
              {orderStatus === 3 && (
                <div
                  className='rounded-[16px] flex justify-center items-center'
                  style={{ background: '#D8FFE7' }}
                >
                  <p className='font-medium text-base flex items-center py-[5px] px-2.5' style={{ color: "#009E2C" }}>
                    <UpdateIcon className="mr-2" />
                    Ready
                  </p>
              </div>
              )}
            </div>
            <div>
              <p className='text-left' style={{ fontSize: '14px', color: '#444' }}>
                {orders?.length} {orders.length === 1 ? 'Item' : 'Items'}
              </p>
            </div>
            <div className='mt-5 itemList pe-2.5 h-[250px] overflow-auto'>
              {orders?.map((item, index) => (
                <div
                  className='items flex gap-5 justify-between items-end mb-1.5 rounded-md p-2.5'
                  key={index}
                  style={{ backgroundColor: ' #F5F5F5' }}
                >
                  <p className='text-gray-700	font-medium text-base'>
                    {item.ItemName}
                  </p>
                  <p className='text-gray-700	font-medium text-base'>
                    {item.ItemQuantity}
                  </p>
                </div>
              ))}
            </div>
            <button
              className={`OrderBoxBtn ${orderStatus === 1
                  ? ''
                  : orderStatus === 2
                    ? 'processingBtn'
                    : 'deliverBtn'
                }`}
              onClick={() => handleDeliver(Number(orderId), orderStatus)}
              disabled={isDisabled && actionLoader != orderId}
            >
              {actionLoader == orderId
                ? (
                    <ClockLoader
                      color={
                        orderStatus === 1
                          ? '#003F9D'
                          : orderStatus === 2
                          ? '#F8A503'
                          : '#009E2C'
                      }
                      loading={true}
                      cssOverride={override}
                      size={24}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                )
                : (orderStatus === 1
                  ? <><DoneAllIcon className="mr-2" /> New order to accept</>
                  : orderStatus === 2
                    ? <>
                        <img
                          src={require('../assets/processing.png')}
                          className="mr-2"
                          alt='Preparing'
                        />
                        Preparing
                      </>
                    : orderStatus === 3
                    ? <>
                        <img
                          src={require('../assets/completed.png')}
                          className="mr-2"
                          alt='Ready to serve'
                        />
                        Ready to serve
                      </>
                    : <>
                        <img
                          src={require('../assets/completed.png')}
                          className="mr-2"
                          alt='Ready to serve'
                        />
                        Pick Order
                      </>
              )}
            </button>
          </div>
          <div className='shadow p-3 border w-[330px] rounded-[12px] break-all overflow-auto h-36 text-sm text-gray-700 bg-white' style={{ boxShadow: '0px 12px 60px 0px rgba(24, 24, 24, 0.12)' }}>
            {cartDetails[index]?.OrderInstructions.length > 0
              ? cartDetails[index].OrderInstructions
              : 'No Order Instructions'
            }
          </div>
        </div>
       )}
    </>
  )
}

export default OrderBox
