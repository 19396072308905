import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import "../styles/foodOrderReceipt.css";


const FoodOrderReceipt = ({ data, onPrintComplete }) => {
  const componentRef = useRef();

 
  const handlePrint = useReactToPrint({
    content: () => componentRef.current, 
    onAfterPrint: onPrintComplete 
  });

  useEffect(() => {
    if (data) {
      handlePrint(); 
    }
  }, [data, handlePrint]);

  if (!data) {
    return null; 
  }

  const { orderDate, items, totalAmount } = data;

  return (
    <div  ref={componentRef} class="container">
      
    <div  class="content">
      <div class="main">
        <div class="content-wrap aligncenter">
          <div class="content-block">
            <h1 style={{fontWeight: 'bold', fontSize:'20px'}}>Food Order Receipt</h1>
          </div>
          <div class="content-block">
            <div class="invoice">
              <div class="invoice-details">
                <p class="invoice-header" >
                <span style={{marginRight:'95px'}}> Token:{data?.items[0]?.CartId}</span> 
                <span style={{ whiteSpace: 'nowrap', paddingRight: '5px' }}>Date:{orderDate}</span>


                </p>
                <table class="invoice-items">
                  <thead>
                    <tr>
                      <th class="item-name-label">Item Name</th>
                      <th class="aligncenter">Quantity</th>
                      <th class="alignright">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.items.map((item) => (
                      <tr key={item?.SNO}>
                      <td class="item-name">{item?.ItemName}</td>
                      <td class="aligncenter">{item?.ItemQuantity}</td>
                      <td class="alignright">₹ {item?.TotalAmount}</td>
                    </tr>
                    ))}
          
                    <tr class="total">
                      <td colspan="2" class="alignright">Total</td>
                      <td class="alignright">₹ {data?.totalAmount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="content-block powered-by">
            @Powered by JETZ
          </div>
        </div>
      </div>
    </div>
  </div>

   
  );
};

export default FoodOrderReceipt;

 