import React, { useState, useEffect } from 'react';
import { mainAxios } from '../middleware/Interceptor';
import Lottie from 'react-lottie';
import * as animationData from '../assets/loader.json';
import { EventHubConsumerClient } from '@azure/event-hubs';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function PickOrders() {
  const [pickOrders, setPickOrders] = useState([]);
  const [preparingOrders, setPreparingOrders] = useState([]);
  const [cookOrderStatus, setCookOrderStatus] = useState([]);
  const [orderDetails, setOrderDetials] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const EventHubCreds = {
    EventHubConnectionString: process.env.REACT_APP_EVENTHUBCONNECTIONSTRING,
    EventHubName: process.env.REACT_APP_EVENTHUBNAME,
    EventHubConsumerGroup: "$Default"
  }

  const {
    EventHubConnectionString,
    EventHubName,
    EventHubConsumerGroup
  } = EventHubCreds;

  const getPreparedOrders = async () => {
    try {
      const getDeliveredOrders = await mainAxios.get('/GetActiveOrders');
      !!getDeliveredOrders?.data?.cartDetails
        ? setCookOrderStatus(getDeliveredOrders?.data?.cartDetails)
        : setCookOrderStatus([]);
      !!getDeliveredOrders?.data?.orderDetails
        ? setOrderDetials(getDeliveredOrders?.data?.orderDetails)
        : setOrderDetials([]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const ProcessEventHubsEvents = (datafromhub) => {
    if (datafromhub
        && datafromhub.length > 0
        && datafromhub[0].body.status === 1
      ) {
        console.log(datafromhub[0].body);
        setCookOrderStatus(prevState => {
          return prevState.map(item => item.CartId === datafromhub[0].body.deliveredDetails[0].CartId
            ? {
                ...item,
                Cook_Status : datafromhub[0].body.deliveredDetails[0].Cook_Status
              }
            : {...item}
          );
        });
    }
  }

  useEffect(() => {
    const preparedOrders = cookOrderStatus?.filter(item => item.Cook_Status === 4);

    const preparingOrder = cookOrderStatus?.filter(item => (item.Cook_Status === 2 || item.Cook_Status === 1));

    const getOrders = orderDetails.filter(item => {
      return preparedOrders.some(item2 => {
        return item.CartId === item2.CartId
      })
    });

    const getPreparingOrders = orderDetails.filter(item => {
      return preparingOrder.some(item2 => {
        return item.CartId === item2.CartId
      })
    });

    const groupByCartId = Object.groupBy(getOrders, ({CartId}) => CartId);
    const groupByCartId2 = Object.groupBy(getPreparingOrders, ({CartId}) => CartId);

    setPickOrders(groupByCartId);
    setPreparingOrders(groupByCartId2);
  }, [cookOrderStatus, orderDetails])

  useEffect(() => {
    const EventHubclient = new EventHubConsumerClient(
      EventHubConsumerGroup,
      EventHubConnectionString,
      EventHubName
    )

    const subscribe = EventHubclient.subscribe({
      processEvents: ProcessEventHubsEvents,
      processError: err => {
        console.log('ErrorfromEventhub', err)
        if (
          err
            .toString()
            .includes('Exceeded the maximum number of allowed receivers')
        ) {
          // toast.warning(
          //   'Disabled Realtime Updates(Exceeded the maximum connection)'
          // )
          console.log(
            'Disabled Realtime Updates(Exceeded the maximum connection)'
          )
          subscribe.close()
          EventHubclient.close()
        }
      }
    })

    return () => {
      subscribe.close()
      EventHubclient.close()
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPreparedOrders();
  }, []);

  return (
    <div className='border radius sm  w-full flex justify-around flex-wrap h-full'>
      {isLoading && (
        <div className="w-[100%] min-h-180 flex items-center justify-center">
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
          />
        </div>
      )}
      {!isLoading
        && (
            Object.keys(pickOrders).length > 0
              || Object.keys(preparingOrders).length > 0
            )
        && (
        <>
          <div className='pb-5'>
            <h1 className='text-center my-2 text-xl font-bold text-green-400'>Pick Your Orders</h1>
            {Object.entries(pickOrders).map((obj, i) => {
              return (
                <div key={i} className='w-[400px] mb-5'>
                  <div className='shadow p-10 border rounded'>
                    <div className='cardHeader text-left flex  flex-row gap-5'>
                      <h1 className='text-blue-800 font-medium text-xl'>Token Number</h1>
                      <h1 className='text-blue-800 font-medium text-xl'>|</h1>
                      <h1 className='text-blue-800 font-medium text-xl'> {`${obj[0]}`}</h1>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className='divider'></div>
          <div className='pb-5'>
            <h1 className='text-center my-2 text-xl font-bold text-red-400'>Waiting Orders</h1>
            {Object.entries(preparingOrders).map((obj, i) => {
              return (
                <div key={i} className='w-[400px] mb-5'>
                  <div className='shadow p-10 border rounded'>
                    <div className='cardHeader text-left flex flex-row gap-5'>
                      <h1 className='text-blue-800 font-medium text-xl'>Token Number</h1>
                      <h1 className='text-blue-800 font-medium text-xl'>|</h1>
                      <h1 className='text-blue-800 font-medium text-xl'> {`${obj[0]}`}</h1>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
      {!isLoading
        && (Object.keys(pickOrders).length === 0
            && Object.keys(preparingOrders).length === 0)
        && (
        <div className='flex justify-center items-center w-full h-180'>
          <div className='w-96 h-96'>
              <img
                src={require('../assets/empty_cart.jpg')}
                alt="Empty Orders"
                className='w-full h-full'
              />
          </div>
        </div>
      )}
    </div>
  )
}

export default PickOrders
