import React, { useEffect, useState } from 'react'
import { mainAxios } from '../middleware/Interceptor';
import Lottie from 'react-lottie';
import * as animationData from '../assets/loader.json';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { EventHubConsumerClient } from '@azure/event-hubs';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function DeliveredOrders() {
  const [ordersDelivered, setOrdersDelivered] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const EventHubCreds = {
    EventHubConnectionString: process.env.REACT_APP_EVENTHUBCONNECTIONSTRING,
    EventHubName: process.env.REACT_APP_EVENTHUBNAME,
    EventHubConsumerGroup: "$Default"
  }

  const {
    EventHubConnectionString,
    EventHubName,
    EventHubConsumerGroup
  } = EventHubCreds;

  const getDeliveredOrders = async () => {
    try {
      setIsLoading(true);
      const getDeliveredOrders = await mainAxios.get('/getdeliveredorders');
      const groupByCartId =
        !!getDeliveredOrders?.data?.orderDetails
          && Object.groupBy(getDeliveredOrders?.data?.orderDetails, ({CartId}) => CartId);

      setOrdersDelivered(groupByCartId);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const ProcessEventHubsEvents = (datafromhub) => {
    if (datafromhub
        && datafromhub.length > 0
        && datafromhub[0].body.status === 2
      ) {
        console.log(datafromhub[0].body);
        const groupByCartId = Object.groupBy(datafromhub[0].body.deliveredDetails, ({CartId}) => CartId);

        setOrdersDelivered(prevState => ({...prevState, ...groupByCartId}));
    }
  }

  useEffect(() => {
    const EventHubclient = new EventHubConsumerClient(
      EventHubConsumerGroup,
      EventHubConnectionString,
      EventHubName
    )

    const subscribe = EventHubclient.subscribe({
      processEvents: ProcessEventHubsEvents,
      processError: err => {
        console.log('ErrorfromEventhub', err)
        if (
          err
            .toString()
            .includes('Exceeded the maximum number of allowed receivers')
        ) {
          
          console.log(
            'Disabled Realtime Updates(Exceeded the maximum connection)'
          )
          subscribe.close()
          EventHubclient.close()
        }
      }
    })

    return () => {
      subscribe.close()
      EventHubclient.close()
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getDeliveredOrders ();
  }, []);

  return (
    <div className='flex flex-wrap gap-5 border radius sm p-5 w-full h-full'>
      {isLoading && (
        <div className="w-[100%] min-h-180 flex items-center justify-center">
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
          />
        </div>
      )}
      {!isLoading && Object.keys(ordersDelivered).length > 0 && (
        Object.entries(ordersDelivered).map((obj, i) => {
          return (
            <div key={i}>
              <div
                className='shadow p-2.5 border w-[330px] rounded-[12px] bg-white'
                style={{
                  border: '1px solid #BBB',
                  boxShadow: '0px 12px 60px 0px rgba(24, 24, 24, 0.12)'
                }}
              >
                <div className='cardHeader text-left mb-2.5 flex justify-between'>
                  <p className='text-slate-800 font-medium text-lg'>
                  Token : {`#${obj[0]}`}
                  </p>
                </div>
                <div>
                  <p className='text-left' style={{ fontSize: '14px', color: '#444' }}>
                    {obj[1]?.length} {obj[1].length === 1 ? 'Item' : 'Items'}
                  </p>
                </div>
                <div className='mt-5 itemList px-2.5 h-[250px] overflow-auto'>
                  {obj[1].map((item, index) => (
                    <div
                      className='items flex gap-5 justify-between items-end mb-1.5 rounded-md p-2.5'
                      key={index}
                      style={{ backgroundColor: ' #F5F5F5' }}
                    >
                      <p className='text-gray-700	font-medium text-base'>
                        {item.ItemName}
                      </p>
                      <p className='text-gray-700	font-medium text-base'>
                        {item.ItemQuantity}
                      </p>
                    </div>
                  ))}
                </div>
                <button
                  className='primaryBtn disabledBtn'
                  disabled
                >
                  <DoneAllIcon className="mr-2" />
                  Delivered
                </button>
              </div>
            </div>
          )
        })
      )}
      {!isLoading && Object.keys(ordersDelivered).length === 0 && (
        <div className='flex justify-center items-center w-full h-180'>
          <div className='w-96 h-96'>
              <img
                src={require('../assets/empty_cart.jpg')}
                alt="Empty Orders"
                className='w-full h-full'
              />
          </div>
        </div>
      )}
    </div>
  )
}

export default DeliveredOrders
